<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <span style="margin-right: 10px"> Sicht </span>
        <Button :label="!body.isR ? `Don't show in Report` : `show in Report`" class="p-button-help mr-2"
          @click="update('isR', !body.isR)" />

        <Button :label="!body.isC ? `Don't show in Clander` : `show in Clander`" class="p-button-help mr-2"
          @click="update('isC', !body.isC)" />
      </template>
      <template #right>
        <a :href="windowOrigin + '/print/' + body.id" target="_blank">
          <Button icon="pi pi-download" class="p-button-help p-mr-2" />
        </a>

        <Button icon="pi pi-times" class="p-button-danger ml-2" @click="$router.go(-1)" />
      </template>
    </Toolbar>

    <div class="form-card row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="massage" class="form-label"> Massage </label>
          <input v-if="body.massageId" class="form-control" id="massage" name="massage"
            :value="JSON.parse(body.massageId.name).de" disabled />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="prices" class="form-label">Preise</label>
          <input v-if="body.pricesId" class="form-control" id="prices" name="prices" :value="body.pricesId.minutes + ' Minutes = ' + body.pricesId.price + '€'
            " disabled />
        </div>
      </div>


      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label"> Created At </label>

          <input
            class="form-control"
            id="phone"
            name="phone"
            :value="$durationFormatFullDate(body.createdAt)"
            disabled
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label"> Last Update At </label>

          <input
            class="form-control"
            id="phone"
            name="phone"
            :value="$durationFormatFullDate(body.updatedAt)"
            disabled
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3" v-if="body.adminsId">
          <label for="user" class="form-label"> Angestellte </label>
          <input class="form-control" id="user" name="user" :value="body.adminsId.fullName" disabled />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="date" class="form-label">Datum</label>
          <input class="form-control" id="date" name="date" :value="$durationFormat(body.start)" disabled />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="time" class="form-label">Zeit</label>
          <input class="form-control" id="time" name="time" :value="$durationFormatFull(body.start, body.end)"
            disabled />
        </div>
      </div>
    </div>
    <div class="form-card row">
      <div class="col-md-2">
        <div class="mb-3">
          <label for="sexType" class="form-label">HERR/FRAU</label>

          <input class="form-control" id="sexType" name="sexType" v-model="body.sexType" disabled />
        </div>
      </div>
      <div class="col-md-5">
        <div class="mb-3">
          <label for="fname" class="form-label">Vorname </label>

          <input class="form-control" id="fname" name="fname" v-model="body.fname" disabled />
        </div>
      </div>
      <div class="col-md-5">
        <div class="mb-3">
          <label for="lname" class="form-label">Nachname </label>

          <input class="form-control" id="lname" name="lname" v-model="body.lname" disabled />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label"> Telefon </label>

          <input class="form-control" id="phone" name="phone" v-model.trim="body.phone" disabled />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="email" class="form-label"> Email Address </label>

          <input class="form-control" id="email" name="email" v-model="body.email" disabled />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="paymentMethod" class="form-label">Typ</label>

          <input v-if="body.paymentMethod == 'cash'" class="form-control" id="paymentMethod" name="paymentMethod"
            value="Externe Gast - Cash" disabled />
          <input v-if="body.paymentMethod == 'online'" class="form-control" id="paymentMethod" name="paymentMethod"
            value="Externe Gast - Online" disabled />
          <input v-if="body.paymentMethod == 'hotel'" class="form-control" id="paymentMethod" name="paymentMethod"
            value="Hotel Gäste" disabled />
          <input v-if="body.paymentMethod == 'Gift'" class="form-control" id="paymentMethod" name="paymentMethod"
            value="Gutschein Einlösung" disabled />

          <input v-if="body.paymentMethod == 'Package'" class="form-control" id="paymentMethod" name="paymentMethod"
            value="Angebot benutzen" disabled />
        </div>
      </div>
      <!-- <div class="col-md-4">
        <div class="mb-3">
          <label for="baytypeAction" class="form-label"> Bay Type </label>

          <input
            class="form-control"
            id="baytypeAction"
            name="baytypeAction"
            :value="body.baytypeAction ? 'Online' : 'Cash'"
            disabled
          />
        </div>
      </div> -->

      <div class="col-md-6">
        <div class="mb-3" v-if="body.paymentMethod == 'cash' || body.paymentMethod == 'online'">
          <label for="paymentDone" class="form-label">
            Bucht Fertig

            <Button v-if="!body.paymentDone" icon="pi pi-check" class="p-button-warning mr-2 ml-2"
              @click="updateDone()" />
          </label>

          <input class="form-control" id="paymentDone" name="paymentDone" :value="body.paymentDone ? 'Erledigt' : '--'"
            disabled />
        </div>
        <div class="mb-3" v-if="body.paymentMethod == 'hotel'">
          <label for="hotel" class="form-label">
            Zimmernummer oder Buchungsnummer
          </label>
          <input class="form-control" id="hotel" name="hotel" v-model="body.hotel" disabled />
        </div>
        <div class="mb-3" v-if="body.paymentMethod == 'Gift' && body.giftsId">
          <label for="giftsId" class="form-label">Geschenk</label>
          <input class="form-control" id="giftsId" name="giftsId" :value="`(${$codePadding(body.giftsId.id)}) ${body.giftsId.price
            }€ from  ${body.giftsId.usersId.sexType}  /  ${body.giftsId.usersId.fname
            }  ${body.giftsId.usersId.lname} `" disabled />
        </div>
        <div class="mb-3" v-if="body.paymentMethod == 'Package' && body.packagesUsersId">
          <label for="packagesUsersId" class="form-label">Paket-ID</label>
          <input class="form-control" id="packagesUsersId" name="packagesUsersId" :value="`${$getByLang(body.packagesUsersId.packagesId.name)} - ${JSON.parse(body.packagesPricesId.name).de
            } (${body.packagesUsersId.packagesPricesId.price} €)`" disabled />
        </div>
      </div>

      <div class="col-md-10">
        <div class="mb-3">
          <label for="address" class="form-label">Adresse</label>
          <input class="form-control" id="address" name="address" :value="body.address" disabled />
        </div>
      </div>

      <div class="col-md-2">
        <div class="mb-3">
          <label for="zip" class="form-label">PLZ </label>
          <input class="form-control" id="zip" name="zip" :value="body.zip" disabled />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="city" class="form-label"> Stadt </label>
          <input class="form-control" id="city" name="city" :value="body.city" disabled />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="country" class="form-label">Land</label>
          <input class="form-control" id="country" name="country" :value="body.country" disabled />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="note" class="form-label"> Notiz</label>
          <textarea class="form-control" id="note" name="note" v-model="body.note" disabled />
        </div>
      </div>

      <div class="col-md-12">
        <DataTable ref="dt" :value="list" class="p-datatable-customers table table-striped">
          <template #header>
            <div class="table-header">
              Massage bestellen
              <Button icon="pi pi-plus" @click="opendds()" />
            </div>
          </template>
          <template #empty>Keine Daten</template>

          <Column filterMatchMode="contains" field="id" header="ID"> </Column>

          <Column filterMatchMode="contains" field="name" header="Name">
            <template #body="slotProps">
              {{ slotProps.data.fname + " " + slotProps.data.lname }}
            </template>
          </Column>
          <Column filterMatchMode="contains" field="date" header="Date">
            <template #body="slotProps">
              {{ $durationFormat(slotProps.data.start) }}
            </template>
          </Column>

          <Column filterMatchMode="contains" field="time" header="Time">
            <template #body="slotProps">
              {{
                $durationFormatFull(slotProps.data.start, slotProps.data.end)
              }}
            </template>
          </Column>

          <Column filterMatchMode="contains" field="time" header="Info">
            <template #body="slotProps">
              {{ JSON.parse(slotProps.data.massageId.name).de }}
              <br />

              {{
                slotProps.data.pricesId.minutes +
                " Minutes = " +
                slotProps.data.pricesId.price +
                "€"
              }}
            </template>
          </Column>

          <Column filterMatchMode="contains" field="id">
            <template #body="slotProps">
              <Button type="button" @click="deleteItem2(slotProps.data.id)" icon="pi pi-trash"
                class="p-button-warning p-button-rounded"></Button>
            </template>
          </Column>
        </DataTable>

        <b-modal v-model="displayMassagesAdd" id="modal-prevent-closing">
          <div class="p-m-0">
            <div class="mb-3">
              <label for="personsNumber" class="form-label"> Massagetyp</label>

              <CascadeSelect class="mb-3" v-model="addNew.massageId" :options="newMassageList" optionLabel="name"
                optionValue="id" optionGroupLabel="name" :optionGroupChildren="['list']" style="width: 100%">
                <template #option="slotProps">
                  <div class="country-item">
                    <img :src="$baseUploadURL + slotProps.option.image" v-if="slotProps.option.image" style="
                        width: 40px;
                        height: 30px;
                        border-radius: 5px;
                        margin-right: 14px;
                      " />

                    <span>{{ slotProps.option.name }}</span>
                  </div>
                </template>
              </CascadeSelect>
            </div>

            <div class="mb-3">
              <label for="personsNumber" class="form-label">
                Preisplan
                <span style="color: red"> pro Person </span>
              </label>
              <b-form-select v-model="addNew.pricesId" class="mb-3">
                <b-form-select-option v-for="item of pricesList" :key="item.id" :value="item.id">
                  {{ $getByLang(item.name) }}

                  {{ item.minutes }} Minuten =
                  {{ item.price }}€</b-form-select-option>
              </b-form-select>
            </div>
          </div>
          <template #modal-footer>
            <Button label="Hinzufügen" icon="pi pi-check" @click="addPayment2" autofocus />
            <Button label="Cencel" icon="pi pi-times" @click="displayMassagesAdd = false" class="p-button-text" />
          </template>
        </b-modal>
      </div>

      <div class="col-md-12">
        <DataTable ref="dt" :value="body.itemsList" class="p-datatable-customers table table-striped">
          <template #header>
            <div class="table-header">
              Product Aufführen
              <Button icon="pi pi-plus" @click="displayProductsAdd = true" />
            </div>
          </template>
          <template #empty>Keine Daten</template>

          <Column field="productsId.pname" header="Name" />
          <Column field="quantity" header="Quantity" />
          <Column field="price" header="Price" />
          <Column field="price" header="Total">
            <template #body="slotProps">
              {{ slotProps.data.price * slotProps.data.quantity }}
            </template>
          </Column>

          <Column field="id" bodyStyle="text-align: center; overflow: visible">
            <template #body="slotProps">
              <Button type="button" icon="pi pi-trash" @click="deleteItem(slotProps.data.id, slotProps.index)"
                class="p-button-warning p-button-rounded"></Button>
            </template>
          </Column>
        </DataTable>

        <Dialog header="Add Product" :visible.sync="displayProductsAdd" 
          containerStyle="width: '50vw'" id="xxxx">
          <div class="p-m-0" style="width: 50%">
            <!-- <div class="branches">
              <label for="category" class="form-label required">
                Kategorie
                <span>*</span>
              </label>
              <CascadeSelect v-model.number="catId" :options="catList" optionLabel="name" optionValue="id"
                optionGroupLabel="name" style="width: 100%" :showClear="true" :optionGroupChildren="['children']"
                placeholder="Select Category" required />
            </div> -->
            <div class="branches">
                <label for="product" class="form-label required">
                  Produkt
                  <span>*</span>
                </label>
                <input type="text" class="form-control" v-model="itemId">
              </div>
            <div class="branches">
              <label for="product" class="form-label required">
                Produkt
                <span>*</span>
              </label>
              <Dropdown :filter="true" v-model.number="paymentAdd.productsId" :options="productsList"
                optionLabel="xname" optionValue="id" placeholder="Select Product" class="w-100" required />
            </div>

            <div style="direction: ltr" class="mb-3 text-left">
              <label for="price" class="form-label required">
                Price
                <span>*</span>
              </label>

              <b-form-spinbutton id="sb-default" v-model.number="paymentAdd.quantity" :min="1"
                class="mb-2"></b-form-spinbutton>
            </div>
          </div>
          <template #footer>
            <Button label="Hinzufügen" icon="pi pi-check" @click="addPayment" autofocus />
            <Button label="Cencel" icon="pi pi-times" @click="displayProductsAdd = false" class="p-button-text" />
          </template>
        </Dialog>
      </div>

      <div class="col-md-12">
        <DataTable ref="dt" :value="list2" class="p-datatable-customers table table-striped">
          <template #header>
            <div class="table-header">Verknüpfte Sitzungen</div>
          </template>
          <template #empty>Keine Daten</template>

          <Column filterMatchMode="contains" field="id" header="ID"> </Column>

          <Column filterMatchMode="contains" field="name" header="Name">
            <template #body="slotProps">
              {{ slotProps.data.fname + " " + slotProps.data.lname }}
            </template>
          </Column>
          <Column filterMatchMode="contains" field="date" header="Date">
            <template #body="slotProps">
              {{ $durationFormat(slotProps.data.start) }}
            </template>
          </Column>

          <Column filterMatchMode="contains" field="time" header="Time">
            <template #body="slotProps">
              {{
                $durationFormatFull(slotProps.data.start, slotProps.data.end)
              }}
            </template>
          </Column>

          <Column filterMatchMode="contains" field="time" header="Info">
            <template #body="slotProps">
              {{ JSON.parse(slotProps.data.massageId.name).de }}
              <br />

              {{
                slotProps.data.pricesId.minutes +
                " Minutes = " +
                slotProps.data.pricesId.price +
                "€"
              }}
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return {
      windowOrigin: window.origin,
      displayProductsAdd: false,
      displayMassagesAdd: false,
      paymentAdd: {
        productsId: null,
        quantity: 0,
      },
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        name: { en: null, de: null },
        body: { en: null, de: null },
        // email: null,
        inhomepage: 0,
      },
      id: null,

      sectionList: [],
      optionsList: [
        { name: "No", value: 0 },
        { name: "Yes", value: 1 },
      ],

      isInvalid: false,
      disabledAdd: false,
      addNew: {
        adminsId: null,
        massageId: null,
        pricesId: null,

        usersId: null,
        email: null,
        phone: null,
        sexType: null,
        fname: null,
        lname: null,
        address: null,
        zip: null,
        city: null,
        country: null,
        paymentMethod: "cash",
      },
      list: [],
      list2: [],
      massageList: [],

      newMassageList: [],


      catId: null,
      catList: [],
      productsList: [],
      itemList: [],
      itemId: null,
    };
  },
  methods: {
    $t(val) {
      return val;
    },

    opendds() {
      this.displayMassagesAdd = true;
      this.addNew = { ...this.body };
      delete this.body.itemsList;
      delete this.body.id;

      this.addNew.appointmentsId = this.id;
      this.addNew.usersId = this.body.usersId.id;
      this.addNew.massageId = this.body.massageId.id;
      this.addNew.pricesId = this.body.pricesId.id;
      this.addNew.adminsId = this.body.adminsId.id;
    },
    addPayment2() {
      if (this.addNew.massageId && this.addNew.pricesId) {
        delete this.addNew.id;
        this.$http.post(`appointments`, [this.addNew]).then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.getData();
            this.displayMassagesAdd = false;
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
    deleteItem2(id) {
      this.$http.delete(`appointments/${id}`).then(
        () => {
          this.$toast.add({
            severity: "success",
            summary: "Done Successfully",
            detail: "Added Successfully",
            life: 3000,
          });
          this.getData();
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },

    update(key, value) {
      let obj = {};
      obj[key] = value;
      this.$http.put(`appointments/${this.id}`, obj).then(() => {
        this.getData();
        this.$toast.add({
          severity: "error",
          summary: "Done Successfully",
          detail: "Update successfully",
          life: 3000,
        });
      });
    },
    updateDone() {
      this.$http
        .put(`appointments/${this.id}`, {
          paymentDone: true,
        })
        .then(
          () => {
            this.getData();
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
    getUser(list) {
      let str = "";
      if (list && list.length > 0) {
        for (const [i, item] of list) {
          if (i == 0) {
            str = item.name;
          } else {
            str = str + " & " + item.name;
          }
        }
      }
      return str;
    },
    xc(value) {
      if (value) {
        return JSON.parse(value);
      }
      return [];
    },
    addPayment() {
      if (this.paymentAdd.productsId && this.paymentAdd.quantity > 0) {
        const d = new Date();

        const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
        const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
          d
        );
        const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

        const price = this.productsList.find(
          (p) => Number(p.id) == Number(this.paymentAdd.productsId)
        );
        this.$http
          .post(`appointments/createAppointmentsItems`, {
            ...this.paymentAdd,
            date: `${ye}-${mo}-${da}`,
            appointmentsId: this.id,
            price: price.price,
            usersId: this.body.usersId.id,
          })
          .then(
            () => {
              this.$toast.add({
                severity: "success",
                summary: "Done Successfully",
                detail: "Added Successfully",
                life: 3000,
              });
              this.getData();
              this.displayProductsAdd = false;
              this.paymentAdd = {
                productsId: null,
                quantity: 0,
              };
            },
            (err) => {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: err.response.data.message,
                life: 3000,
              });
            }
          );
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
    deleteItem(id, index) {
      this.$http.post(`appointments/deleteAppointmentsItems/${id}`).then(
        () => {
          this.$toast.add({
            severity: "success",
            summary: "Done Successfully",
            detail: "Added Successfully",
            life: 3000,
          });
          this.getData();
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    getData() {
      this.$http.get(`appointments/${this.id}`).then(
        (res) => {
          // if (res.data.offTime == 1) {
          //   this.$route.go(-1);
          // } else {
          this.body = res.data.data;
          // }

          this.$http
            .get(`appointments?appointmentsId=${this.id}`)
            .then((res) => {
              this.list = res.data.data;
            });

          let url = `appointments?usersId=${this.body.usersId.id}&sexType=${this.body.sexType}&fname=${this.body.fname}&lname=${this.body.lname}`;
          url += `&paymentMethod=${this.body.paymentMethod}`;
          url += `&createdAtStart=${this.$durationFormatCraetedAt(
            this.body.createdAt,
            "s"
          )}`;
          url += `&createdAtEnd=${this.$durationFormatCraetedAt(
            this.body.createdAt,
            "e"
          )}`;

          if (this.body.country) {
            url += `&country=${this.body.country}`;
          }

          if (this.body.city) {
            url += `&city=${this.body.city}`;
          }

          if (this.body.zip) {
            url += `&zip=${this.body.zip}`;
          }

          if (this.body.address) {
            url += `&address=${this.body.address}`;
          }

          if (this.body.hotel) {
            url += `&hotel=${this.body.hotel}`;
          }

          if (this.body.email) {
            url += `&email=${this.body.email}`;
          }

          if (this.body.giftsId) {
            url += `&giftsId=${this.body.giftsId.id}`;
          }

          if (this.body.packagesUsersId) {
            url += `&packagesUsersId=${this.body.packagesUsersId.id}`;
          }

          this.$http.get(url).then((res) => {
            let list_x = res.data.data;

            for (const item of list_x) {
              console.log(item.id, this.id);
              console.log(Number(item.id) != Number(this.id));

              if (Number(item.id) != Number(this.id)) {
                this.list2.push(item);
              }
            }

            if (this.body.uuid) {
              let url2 = `appointments?uuid=${this.body.uuid}`;
              this.$http.get(url2).then((res2) => {
                const list2 = res2.data.data;
                for (const item of list2) {
                  console.log(
                    item.id,
                    this.id,
                    this.list2.findIndex((el) => el.id == item.id) == -1
                  );
                  console.log(Number(item.id) != Number(this.id));
                  console.log(
                    this.list2.findIndex((el) => el.id == item.id) == -1
                  );
                  console.log(
                    this.list2.findIndex((el) => el.id == item.id) == -1 &&
                    Number(item.id) != Number(this.id)
                  );

                  if (
                    this.list2.findIndex((el) => el.id == item.id) == -1 &&
                    Number(item.id) != Number(this.id)
                  ) {
                    this.list2.push(item);
                  }
                }
              });
            }
          });
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
  },
  created() {


    this.$http.get(`categories/getTree?linkOrSection=section`).then((res) => {
      let catList = res.data.data;
      for (let cat of catList) {
        cat.name = this.$getByLang(cat.name);
        for (let child of cat.children) {
          child.name = this.$getByLang(child.name);
        }
      }

      this.catList = catList;
    });

    this.$http.get(`products`).then((res) => {
      let itemList = res.data.data;
      for (let item of itemList) {
        item.xname = item.code + " - " + item.pname;
      }
      this.productsList = itemList;


    });
    // this.$http.get(`products`).then((res) => {
    //   this.productsList = res.data.data;
    // });
    // this.$http.get(`masor/getById/${this.id}`).then((res) => {
    //   this.productsList = res.data;
    // });
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getData();
    }

    this.$http.get(`massage`).then(
      (res) => {
        if (res.data) {
          this.massageList = res.data.data;

          const newMassageList = [];
          for (const item of this.massageList) {
            if (
              item.sectionsId &&
              newMassageList.find(
                (el) => Number(el.id) == Number(item.sectionsId.id)
              )
            ) {
              const index = newMassageList.findIndex(
                (el) => Number(el.id) == Number(item.sectionsId.id)
              );
              newMassageList[index].list.push({
                ...item,
                name: this.$getByLang(item.name),
              });
            } else {
              newMassageList.push({
                ...item.sectionsId,
                name: this.$getByLang(item.sectionsId.name),
                list: [{ ...item, name: this.$getByLang(item.name) }],
              });
            }
          }

          this.newMassageList = newMassageList;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  },
  watch: {
    "addNew.massageId"(val) {
      if (val) {
        this.pricesList = this.massageList.find(
          (el) => el.id == val
        ).pricesList;
        this.addNew.pricesId = this.pricesList[0].id;
      }
    },
    catId: function (val) {
      if (val) {
        console.log("val", val);
        for (const i of this.itemList) {
          if (i.categoriesId && i.categoriesId.id == val) {
            this.productsList.push(i);
          }
        }
      }
    },
    itemId: function (val) {
      if (val) {
        const item = this.productsList.find((i) => String(i.code) == String(val));
        if (item) {
          this.paymentAdd.productsId = item.id;

        }
      }
    }
  },
};
</script>


<style>
.branches label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}

#xxxx {
  width: 50vw;
  text-align: center;
}

#xxxx .p-dialog-content {
  height: 90vh;
}
</style>
